<template>
<v-container grid-list-xl fluid>
    <v-layout row wrap>
        <v-flex lg12>
            <v-card>
                <hpo-filter :headers="headers" :viewName="viewName" v-on:applyFilter="applyFilter" v-on:quickFilter="quickFilter"></hpo-filter>
                <v-data-table fixed-header height="750px"   :headers="headers" :items="items" :options.sync="pagination" :server-items-length="total_rows" :footer-props="items_per_page" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                    <template v-slot:header="{ props }"><table-header-filter :headers="props.headers" :viewName="viewName" v-on:applyFilter="applyFilter"></table-header-filter></template>
                    <template v-slot:item="{ item }">
                        <tr :id="'r-' + item.ID">
                            <td>{{ item.TransactionCode}}</td>
                            <td><a target="blank" :href="(item.OrderType == 1 ? '/don-hang/' : '/ky-gui/') + item.OrderID">{{(item.OrderType == 1 ? 'MD' : 'KG') +item.OrderID}}</a></td>
                            <td>{{ item.Username}}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.PaymentStatus, payment_status)}">{{ item.PaymentStatus | display_value(payment_status) }}</td>
                            <td v-bind:style="{ 'color': get_status_color(item.Status, package_status)}">{{ item.Status | display_value(package_status) }}</td>
                            <td class="truncate">
                                <v-edit-dialog
                                :return-value.sync="item.StoreVNNote"
                                large
                                lazy
                                persistent
                                @save="saveItem(item)"
                                >
                                    <v-tooltip top absolute right offset-overflow max-width="250" color="light-green lighten-5 black--text">
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">{{ item.StoreVNNote }}</span>
                                        </template>
                                        <span>{{ item.StoreVNNote }}</span>
                                    </v-tooltip>
                                    <template v-slot:input>
                                        <v-textarea
                                        v-model="item.StoreVNNote"
                                        label="Ghi chú"
                                        autofocus
                                        ></v-textarea>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="truncate">
                                <v-edit-dialog
                                :return-value.sync="item.ShippingInfo"
                                large
                                lazy
                                persistent
                                @save="saveItem(item)"
                                >
                                    <v-tooltip top absolute right offset-overflow max-width="250" color="light-green lighten-5 black--text">
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">{{ item.ShippingInfo }}</span>
                                        </template>
                                        <span>{{ item.ShippingInfo }}</span>
                                    </v-tooltip>
                                    <template v-slot:input>
                                        <v-textarea
                                        v-model="item.ShippingInfo"
                                        label="Thông tin nhận hàng"
                                        autofocus
                                        ></v-textarea>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="truncate">
                                <v-edit-dialog
                                :return-value.sync="item.ShippingMethod"
                                large
                                lazy
                                persistent
                                @save="saveItem(item)"
                                >
                                    <v-tooltip top absolute right offset-overflow max-width="250" color="light-green lighten-5 black--text">
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">{{ item.ShippingMethod }}</span>
                                        </template>
                                        <span>{{ item.ShippingMethod }}</span>
                                    </v-tooltip>
                                    <template v-slot:input>
                                        <v-textarea
                                        v-model="item.ShippingMethod"
                                        label="Hình thức gửi"
                                        autofocus
                                        ></v-textarea>
                                    </template>
                                </v-edit-dialog>
                            </td>
                            <td class="text-center">
                                <v-tooltip top v-if="item.Status == 7">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" medium class="mr-2" @click="deliverPackage(item.ID, 6)">fa-undo</v-icon>
                                    </template>
                                    <span>Huỷ xuất kho</span>
                                </v-tooltip>
                                <v-tooltip top v-if="item.Status == 6">
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" medium class="mr-2" @click="deliverPackage(item.ID, 7)">fa-dolly-flatbed</v-icon>
                                    </template>
                                    <span>Xuất kho</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-card>
        </v-flex>
    </v-layout>
</v-container>
</template>

<script>
import { mapState } from "vuex";
import configs from "@/configs";
import Filtering from "@/components/Filtering";
import _ from "lodash";

export default {
    components: {
        "hpo-filter": Filtering
    },
    data() {
        return {
            headers: [
                {
                    text: "Mã bill",
                    value: "TransactionCode",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Mã đơn hàng",
                    value: "OrderID",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Khách hàng",
                    value: "Username",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Trạng thái công nợ",
                    value: "PaymentStatus",
                    filterable: true,
                    values: configs.PAYMENTORDER_STATUS_LIST,
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Trạng thái",
                    value: "Status",
                    filterable: true,
                    values: configs.PACKAGE_STATUS,
                    dataType: configs.DATA_TYPE["Enum"]
                },
                {
                    text: "Ghi chú",
                    value: "StoreVNNote",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Thông tin nhận hàng",
                    value: "ShippingInfo",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Hình thức gửi",
                    value: "ShippingMethod",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["String"]
                },
                {
                    text: "Thao tác",
                    value: "",
                    align: "center",
                    sortable: false,
                    filterable: false
                }
            ],
            payment_status: configs.PAYMENTORDER_STATUS_LIST,
            package_status: configs.PACKAGE_STATUS,
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "deliverable_list",
            filterConditions: [],
            quickFilterConditions: [],
            requireRules: [v => !!v || "Trường dữ liệu không được để trống."],
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            items: state => state.deliverable.all.data,
            total_rows: state => state.deliverable.all.total,
            loading: state => state.deliverable.loading
        }),
        tableConfig() {
            return {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            }
        }
    },
    methods: {
        filter_data: function () {
            this.$store.dispatch("deliverable/getList", this.tableConfig);
        },
        applyFilter(filterConditions) {
            let sortBy = this.pagination.sortBy
            let sortDesc = this.pagination.sortDesc
            this.pagination = configs.PAGINATION;
            this.pagination.sortBy = sortBy;
            this.pagination.sortDesc = sortDesc;
            this.filterConditions = filterConditions;
            this.filter_data();
        },
        quickFilter(filterConditions) {
            this.quickFilterConditions = filterConditions;
            this.filter_data();
        },
        deliverPackage(id, status) {
            var param = {
                pagination: this.pagination,
                filter: this.filterConditions,
                quickFilter: this.quickFilterConditions
            };
            this.$store.dispatch("deliverable/setDetail", {param: this.tableConfig, data: {
                'ID': id,
                'Status': status
            }});
        },
        get_status_color(val, enums) {
            return this.$options.filters.display_color(val, enums);
        },
        saveItem(item) {
            this.$store.dispatch("orderTransaction/setDetail", {'data': {'StoreVNNote': item.StoreVNNote, 'ShippingInfo': item.ShippingInfo, 'ShippingMethod': item.ShippingMethod}, 'id': item.ID});
        }
    },
    mounted() {
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_CREATE_DATA", this.createData);
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$on("CONFIRMED_REMOVE_DATA", id => {
            this.$store.dispatch("feeBuy/removeDetail", id);
            setTimeout(this.filter_data, 200);
        });
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_CREATE_DATA", this.createData);
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
        this.$root.$off("CONFIRMED_REMOVE_DATA");
    }
};
</script>
